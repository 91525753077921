import { useState, useEffect } from "react";
import { User, UsersService } from "../services/identity";

export const useUser = (userId:string) => {
    const [user, setUser] = useState<User|null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error|null>(null);

    useEffect(() => {
        if (!userId) return;

        const fetchUser = async () => {
            setIsLoading(true);
            try {
                const response = await UsersService.getUser(userId);
                setUser(response);
            } catch (err) {
                setError(err as Error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUser();
    }, [userId]);

    return { user, isLoading, error };
};