import { Button, Grid, MenuItem, Select, TextField } from "@mui/material"

export const VideoEditorProjectBar = (): JSX.Element => {
    return <Grid container p={1} pb={0}>
        <Grid item xs={6}>
            <Grid container columnSpacing={2}>
                <Grid item>
                    <TextField size="small" label="Name" variant="outlined" />
                </Grid>
                <Grid item>
                    <Select size="small" value={0}  variant="outlined">
                        <MenuItem value="0">
                            <em>Select game</em>
                        </MenuItem>
                        <MenuItem value={1}>Cs</MenuItem>
                        <MenuItem value={2}>Dota</MenuItem>
                        <MenuItem value={3}>Other</MenuItem>
                    </Select>
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={6}>
            <Grid container justifyContent={"flex-end"} spacing={2}>
                <Grid item >
                    <Button
                        color="secondary"
                        variant={"contained"}
                    >
                        Tutorial
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant={"contained"}
                    >
                        Save and render
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}