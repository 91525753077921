import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HomePage } from '../pages/HomePage';
import { ExamplePage } from '../pages/ExamplePage';
import { Header } from '../components/Header/Header';
import { HeroHeader } from '../components/Header/HeroHeader';
import { ProfilePage } from '../pages/ProfilePage';
import { VideosPage } from '../pages/VideosPage';
import { UploadSnackbar } from '../components/Upload/UploadSnackbar';
import { Snackbar, SnackbarContent } from '@mui/material';
import { Auth } from '../components/Auth/Auth';
import { AdminPage } from '../pages/AdminPage';
import { EditVideoPage } from '../pages/EditVideoPage';


export const AppRoutes = (): JSX.Element => {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={
                    <>
                        <HeroHeader />
                        <HomePage />
                    </>
                } />
                <Route path="/example" element={<ExamplePage />} />
                <Route path="/profile/:id" element={<ProfilePage />}/>
                <Route path="/videos" element={<VideosPage />}/>
                <Route path="/admin" element={<AdminPage />}/>
                <Route path="/video/:id" element={<EditVideoPage />}/>
            </Routes>
            <Auth>
                <UploadSnackbar />
            </Auth>
        </Router>
    );
};
