/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetUsersQuery } from '../models/GetUsersQuery';
import type { UpdateUserCommand } from '../models/UpdateUserCommand';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * @param query 
     * @returns User Success
     * @throws ApiError
     */
    public static getUsers(
query?: GetUsersQuery,
): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/identity/api/Users',
            query: {
                'query': query,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }

    /**
     * @returns User Success
     * @throws ApiError
     */
    public static getCurrentUser(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/identity/api/Users/Current',
            errors: {
                403: `Forbidden`,
            },
        });
    }

    /**
     * @param id 
     * @returns User Success
     * @throws ApiError
     */
    public static getUser(
id: string,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/identity/api/Users/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }

    /**
     * @param id 
     * @param requestBody 
     * @returns void 
     * @throws ApiError
     */
    public static updateUser(
id: string,
requestBody?: UpdateUserCommand,
): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/identity/api/Users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
