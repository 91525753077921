import { useParams } from "react-router-dom";
import * as React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, Card, CardActions, CardContent, CircularProgress, Fab, FormControl, Grid, Input, InputLabel, Paper, Snackbar, Typography } from "@mui/material";
import { ProfileForm } from "../components/Profile/ProfileForm";
import { useUser } from "../hooks/UserHooks";
import { ErrorMessage } from "../components/ErrorMessage";
import { useCurrentUserStore } from "../hooks/CurrentUserStore";

export const AdminPage = (): JSX.Element => {
    
    const { user } = useCurrentUserStore();

    if(!user)
    {
        return <></>
    }
    

    return <Box sx={{ flexGrow: 1, mt: 4 }}>
        <Grid justifyContent={"center"} container>
            <Grid item xs={11.6}>
                <p>admin page</p>
            </Grid>
        </Grid>
    </Box>
}