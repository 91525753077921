import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { ToggleThemeMenu } from './ToggleThemeMenu';
import { HeaderOptions } from './HeaderOptions';
import { Auth } from '../Auth/Auth';


// const pages = ['Example', 'Videos'];
const pages: HeaderLinkProps[] = [{ page: 'Example' }, { page: 'Videos', requiresUser: true }, { page: 'Admin', requiresAdmin: true }];

interface HeaderLinkProps {
    page: string;
    requiresUser?: boolean;
    requiresAdmin?: boolean;
}

const HeaderLink = (props: HeaderLinkProps): JSX.Element => {

    if (props.requiresUser || props.requiresAdmin) {
        return <Auth requiresAdmin={props.requiresAdmin}>
            <Link component={RouterLink} color="inherit" underline='none' to={"/" + props.page.toLowerCase()}>
                {props.page}
            </Link>
        </Auth>
    }

    return <Link component={RouterLink} color="inherit" underline='none' to={"/" + props.page.toLowerCase()}>
        {props.page}
    </Link>
}

export const Header = (): JSX.Element => {

    const location = useLocation();
    const theme = useTheme();
    const [isTransparent, setIsTransparent] = useState(location.pathname === '/');
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);


    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const headerColor = theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.background.paper;

    // Update the AppBar style based on the route change
    useEffect(() => {
        setIsTransparent(location.pathname == '/');
    }, [location]);

    return (
        <Box>
            <AppBar
                position={isTransparent ? 'absolute' : 'static'}
                key={location.pathname}
                elevation={isTransparent ? 0 : 4}
                enableColorOnDark={true}
                color='default'
                style={isTransparent ? { backgroundColor: "rgba(0,0,0,0.0)"  } : {}}
                >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Link component={RouterLink} color="inherit" underline='none' to={"/"}>
                            <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                        </Link>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page, index) => (
                                    <MenuItem key={index} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">
                                            <HeaderLink page={page.page} requiresAdmin={page.requiresAdmin} requiresUser={page.requiresUser} />
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page, index) => (
                                <Typography key={index} textAlign="center" sx={{ mr: 2 }}>
                                    <HeaderLink page={page.page} requiresAdmin={page.requiresAdmin} requiresUser={page.requiresUser} />
                                </Typography>
                            ))}
                            <Auth requiresAdmin={true}>
                                <Link component={RouterLink} hrefLang='/video/api/hangfire/' rel="noopener noreferrer" target='_blank' to={"/video/api/hangfire/"} textAlign={"center"} underline='none' color={"inherit"}>
                                    <Typography>
                                        Hangfire
                                    </Typography>
                                </Link>
                            </Auth>
                        </Box>
                        <HeaderOptions />
                        <ToggleThemeMenu />
                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
};