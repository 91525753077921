import './styles/App.scss';
import './styles/styles.scss';
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { AppRoutes } from './routes/AppRoutes';
import {  ThemeProvider } from '@mui/material/styles';
import useThemeStore from './hooks/ThemeStore';
import { CssBaseline } from '@mui/material';





function App() {

  const themeStore = useThemeStore();

  return (
    <div style={{ backgroundColor: themeStore.theme.palette.background.default }}>
      <ThemeProvider theme={themeStore.theme}>
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </div>
  );
}


export default App;
