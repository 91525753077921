import { Avatar, Box, Button, IconButton, Link, Menu, MenuItem, Skeleton, Tooltip, Typography } from "@mui/material"
import { Route, Link as RouterLink, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { OAuthService, User, UsersService } from "../../services/identity";
import { useCurrentUserStore } from "../../hooks/CurrentUserStore";
import { DiscordIcon } from "./DiscordIcon";
import { getDiscordAvatar } from "../../services/DiscordService";


export const HeaderOptions = (): JSX.Element => {

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const { user, loading, fetchUser } = useCurrentUserStore();
    const location = useLocation();

    useEffect(() => {
        if (user === null && loading) {
            fetchUser();
        }

    }, [user, loading, fetchUser]);

    if (loading) {
        return <Skeleton variant="circular" width={40} height={40} />; // Loading indicator
    }

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const logout = async () => {
        setAnchorElUser(null);
        await OAuthService.logout();
        await fetchUser();
    }

    const redirectUri = `${location.pathname}`;
    const loginUrl = `/identity/api/OAuth/Login?redirectUri=${encodeURIComponent(redirectUri)}`;


    if (user == null) {
        return <Box sx={{ flexGrow: 0 }}>
            <a href={loginUrl}>
                <Button startIcon={<DiscordIcon />} variant="contained">Login with discord</Button>
            </a>
        </Box>
    }

    return <>
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user.userName ?? ""} src={getDiscordAvatar(user)} />
                </IconButton>
            </Tooltip>
            <Menu
                sx={{
                    '& .MuiMenuItem-root': { // Targeting all MenuItem components
                        textAlign: 'right',
                    },
                    '& .MuiPaper-root': {
                        minWidth: 200,
                        maxHeight: 300,
                        // ... other styles ...
                    }
                }}
                disableScrollLock={true}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                <Link color="inherit" underline='none' component={RouterLink} to={"/profile/" + user.id}>
                    <MenuItem onClick={handleCloseUserMenu}>
                        <Typography textAlign="center">Profile</Typography>
                    </MenuItem>
                </Link>

                <MenuItem onClick={logout}>
                    <Typography textAlign="center">Logout</Typography>
                </MenuItem>
            </Menu>
        </Box>
    </>
}