// HeroHeader.tsx
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { SwayingText } from '../Animation/SwayingText';
import Squares from '../Square/Squares';

export const HeroHeader = (): JSX.Element => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main; // Accessing primary color
    const secondaryColor = theme.palette.secondary.main; // Accessing secondary color

    const squareSize = 1800;
    const transform = 'translate(' + -squareSize / 2 + "px, -50%)";


    return (
        <Box position="relative" width="100vw" height="100vh" overflow={"hidden"} sx={{ backgroundColor: "#343434" }}>
            <video
                autoPlay
                loop
                muted
                style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: 'blur(12px)',
                }}
                poster="https://kosatuppspaces.fra1.cdn.digitaloceanspaces.com/kosatupp2/static/heroheader-thumbnail.jpg"
            >
                <source src="https://kosatuppspaces.fra1.cdn.digitaloceanspaces.com/kosatupp2/static/heroheader.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <Grid
                container
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
                alignItems="center"
                justifyContent="center"
            >
                <Grid item>
                    <Squares
                        squares={15}
                        size={squareSize}
                        color1={primaryColor}
                        color2={secondaryColor}
                        transparency={1}
                        style={{
                            position: 'absolute',
                            left: '0',
                            top: '100%',
                            transform: transform, // Adjust for vertical centering and rotation
                        }}
                    />
                </Grid>
                <Grid item sx={{ zIndex: 2 }}>
                    {/* <Typography
                        variant="h1"
                        component="h1"
                        style={{ color: '#fff', textAlign: 'center',zIndex: 2 }}
                        sx={{ fontFamily: "'IBM Plex Mono', monospace" }}
                    >   
                    Kosa Tupp 2.0
                    </Typography> */}
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%', // Adjust for right spacing
                            transform: 'translate(-50%,-50%)',
                            zIndex: 10,
                            textAlign: 'center'
                        }}
                    >
                        <Typography variant="h1" sx={{
                            color: 'white',
                            fontFamily: "'IBM Plex Mono', monospace"
                        }}>
                            Kosa Tupp 2.0
                        </Typography>
                    </Box>
                </Grid>

            </Grid>
        </Box>
    );
};
