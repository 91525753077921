

import { CircularProgress, Grid, IconButton, Slide, SlideProps, Snackbar, SnackbarContent, SpeedDial, SpeedDialIcon, makeStyles, useTheme } from "@mui/material"
import { UploadContainer } from "./UploadContainer"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useState } from "react";
import { useUploadSnackbarState, useUploadedVideos } from "../../hooks/UploadVideoStore";
import { CircularProgressWithLabel } from "../Common/CircularProgressWithlabel";

const SlideTransition = (props: SlideProps): JSX.Element => {
    return <Slide {...props} direction="up" />;
}


export const UploadSnackbar = (): JSX.Element => {

    // const [isOpen, setIsOpen] = useState(false);
    const [isOpen, setState] = useUploadSnackbarState(state => [state.open,state.setState]);
    const uploadedFiles = useUploadedVideos(state => state.uploadedFiles);

    const averageProgress = uploadedFiles.reduce((acc, file) => acc + file.progress, 0) / uploadedFiles.length;

    const theme = useTheme();

    const icon = uploadedFiles.length > 0 ? <CircularProgressWithLabel progress={averageProgress} color="secondary" /> : <FileUploadIcon/>

    return <>
        {!isOpen && (
            <SpeedDial
                ariaLabel="SpeedDial basic example"
                onClick={() => setState(true)}
                sx={{ position: 'fixed', bottom: 16, right: 16, padding:0,margin:0 }}
                icon={
                    icon
                }
                // icon = {<CircularProgress color="secondary" variant="determinate" value={0} />}
            />
        )}
        <Snackbar TransitionComponent={SlideTransition} open={isOpen} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
            <SnackbarContent elevation={3} sx={{
                '& .MuiSnackbarContent-message': {
                    width: '100%', // Extend the width
                },
                backgroundColor: theme.palette.background.default
            }} message={
                <>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"center"}>
                            <IconButton onClick={() => { setState(false) }}>
                                <ExpandMoreIcon fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <UploadContainer />
                </>
            } />
        </Snackbar>
    </>
}