import { Box, Button, Grid, IconButton, LinearProgress, Paper, Typography, useTheme } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';

export interface UploadProgressBarProps {
    title: string;
    progress: number;
    error: boolean;
    errorMessage?: string;
    onAbort?: () => void
}

export const UploadProgressBar = (props: UploadProgressBarProps): JSX.Element => {

    const theme = useTheme();

    const errorColor = theme.palette.mode == "dark" ? theme.palette.error.dark : theme.palette.error.light;
    const error = props.error;

    return <Box>
        <Grid item mt={1} xs={12}>
            <Paper sx={error ? {backgroundColor : errorColor} : {}} elevation={2}>
                <Grid p={1}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item xs={11}>
                            <Typography sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                color: error ? theme.palette.error.contrastText : theme.palette.text.primary
                            }}>
                                {error ? props.errorMessage : props.title}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton onClick={props.onAbort}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <LinearProgress variant="determinate" color={error? "error" : "primary"} value={props.progress} />
                </Grid>
            </Paper>
        </Grid>
    </Box>
}