import { Grid, IconButton, Slider, Typography } from "@mui/material";
import { Timeline } from "./Timeline";
import { Track } from "./Track";
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export interface TrackContainerProps {
    tracks: TrackProps[];
}

export interface TrackProps {
    id: string;
    name: string;
    type: "movie" | "image";
}

const TrackItem = (track: TrackProps) => (
    <Grid key={track.id} container spacing={0} alignItems="center">
        <Grid item xs={2}>
            <Grid container spacing={0} alignItems="center">
                <Grid item>
                    <IconButton><DragIndicatorIcon fontSize="small" /></IconButton>
                </Grid>
                <Grid item >
                    <Typography variant="body2">{track.name}</Typography>
                </Grid>
                <Grid item>
                    <IconButton><DeleteIcon fontSize="small" /></IconButton>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={10}>
            <Grid container spacing={0} alignItems={"center"}>
                <Track />
            </Grid>
        </Grid>
    </Grid>
);

export const TrackContainer = (props: TrackContainerProps): JSX.Element => {
    return (
        <Grid container sx={{ overflow: "hidden" }}>
            <Grid item xs={2}>

            </Grid>
            <Grid item xs={10}>
                <Timeline time={5} />
            </Grid>
            {props.tracks.map((track, index) => {
                return (
                    <TrackItem key={track.id} {...track} />
                )
            })}
        </Grid>
    )
}