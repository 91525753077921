import { useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import { Button, CircularProgress, Grid, Paper } from "@mui/material";
import { ProfileForm } from "../components/Profile/ProfileForm";
import { useUser } from "../hooks/UserHooks";
import { ErrorMessage } from "../components/ErrorMessage";
import { VideoContainer, VideoContainerProps } from "../components/Video/VideoContainer";
import { VideoUploadButton } from "../components/Video/VideoUploadButton";
import { SortOrder, useVideos } from "../hooks/VideoHooks";
import { VideoStateEnum } from "../services/video";
import { useFetchStatus } from "../hooks/VideoStausHooks";


export const VideosPage = (): JSX.Element => {

    const videoStatus = useFetchStatus();
    const [videos, isLoading] = useVideos(videoStatus?.videoId ?? "",(x) => x.fileCreated, SortOrder.Ascending);


    if (isLoading) {
        <p>Loading</p>
    }
    const videoContainerVideos: VideoContainerProps = {
        videos: []
    };

    videos.map((video) => {
        videoContainerVideos.videos.push({
            id: video.id ?? "",
            name: (video.name ?? ""),
            thumbnail: video.thumbnailUrl ?? "",
            link: "/video/" + video.id,
            blur: video.videoStateId == VideoStateEnum.WAITING_FOR_LOW_QUALITY,
            isProcessing: (videoStatus?.videoId ?? "") == video.id,
            progress: (videoStatus?.percentage ?? 0) * 100
        })
    });

    return <Box sx={{ flexGrow: 1, mt: 4 }}>
        <Grid justifyContent={"center"} container>
            <Grid item xs={11.6}>
                    <Box>
                        <Grid justifyContent={"center"} container>
                            <Grid item xs={12} >
                                <VideoUploadButton />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={videos.length > 0 ? { mt: 1 } : { mt: 0 }}>
                        <VideoContainer videos={videoContainerVideos.videos} />
                    </Box>
            </Grid>
        </Grid>
    </Box>
}