import React from 'react';
import './SquareStyle.scss';

interface SquaresProps {
    squares: number;
    size: number;
    color1: string; // Hexadecimal color
    color2: string; // Hexadecimal color
    transparency: number; // A value between 0 (fully transparent) and 1 (fully opaque)
    style?: React.CSSProperties;
}

const Squares = (props: SquaresProps): JSX.Element => {
    const getIntermediateColor = (step: number): string => {
        const mix = (start: number, end: number) => Math.round(start + (end - start) * step / props.squares);
        const [r1, g1, b1] = props.color1.match(/\w\w/g)!.map(hex => parseInt(hex, 16));
        const [r2, g2, b2] = props.color2.match(/\w\w/g)!.map(hex => parseInt(hex, 16));

        // const alpha = 1 - ((1 - step / props.squares) * props.transparency); // Adjust transparency based on the square's position
        const alpha = step / props.squares * props.transparency; // Increase transparency as squares get closer to the center

        // const alpha = props.transparency;

        return `rgba(${mix(r1, r2)}, ${mix(g1, g2)}, ${mix(b1, b2)}, ${alpha})`;
    };

    const rotationCenter = props.size / 2;

    const animationDelay = 1; // Delay in seconds between each square's animation

    const squaresArray = Array.from({ length: props.squares }, (_, i) => ({
        size: props.size * (1 - i / props.squares),
        color: getIntermediateColor(i),
        animationDelay: i * animationDelay
    }));

    return (
        <div style={props.style}>
            <svg width={props.size} height={props.size} style={{
                transform: `rotate(45deg)`,
                transformOrigin: `${rotationCenter}px ${rotationCenter}px`,
            }}>
                {squaresArray.map((square, i) => (
                    <rect
                        key={i}
                        x={(props.size - square.size) / 2}
                        y={(props.size - square.size) / 2}
                        width={square.size}
                        height={square.size}
                        fill={square.color}
                        className="square"
                        style={{
                            animationDelay: `${i * 0.2}s` // Delay based on the index
                        }}
                    />
                ))}
            </svg>
        </div>
    );
};

export default Squares;
