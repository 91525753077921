import { useCurrentUserStore } from "../../hooks/CurrentUserStore"

export interface AuthProps {
    requiresAdmin?: boolean;
    children: JSX.Element | JSX.Element[]
}

export const Auth = (props: AuthProps) : JSX.Element => {
    const {user} = useCurrentUserStore();

    const requiresAdmin = props.requiresAdmin ?? false;

    if(user === null)
    {
        return <></>;
    }

    if(requiresAdmin && !user.isAdmin)
    {
        return <></>;
    }

    return <>
        {props.children}
    </>
}