import axios, { AxiosError, AxiosResponse, CancelTokenSource } from "axios";

export const spacesConfig =  {
    digitalOceanSpaces: 'https://kosatuppspaces.fra1.digitaloceanspaces.com/',
    bucketName: 'kosatuppspaces'
}

export const GetSpaceFile = (name:string,folder: SpaceFolder) => {
    return spacesConfig.digitalOceanSpaces + getFolder(folder) + name;
}

export enum SpaceFolder {
    Thumbnails,
    Raw,
    Cut,
    Youtube,
    LQ,
    Static,
}

export const getFolder = (folder:SpaceFolder) => {
    var folderName = SpaceFolder[folder];
    if(folder === SpaceFolder.Static)
    {
        return folderName + "/";
    }
    var env = window.location.hostname === "localhost" ? "Test" : "Prod";
    return env + "/" + folderName + "/";
}

export const uploadFile = async (
    presignedUrl: string,
    file:File, 
    onProgress: (progress:number) => void,
    cancelTokenSource: CancelTokenSource,
    onError: (error: AxiosError) => void,
    ) => 
{
    return await axios.put(presignedUrl,file,
    {
        headers: {
            "Content-Type" : "video/mp4",
            "x-amz-acl" : "public-read"
        },
        onUploadProgress : (progress) => {
            let percent = Math.min(((progress.loaded / progress.total!)*100),99.9);
            onProgress(percent);
        },
        cancelToken: cancelTokenSource.token
    }).catch((error: AxiosError) => {
        if (axios.isCancel(error)) {
            onError(error);
        } else {
            // Handle other errors
            console.error("Error during file upload:", error);
            onError(error);
        }
    });
}