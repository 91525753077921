import { Box, Grid, Typography } from "@mui/material"

export interface ErrorProps {
    message: string
}

export const ErrorMessage = (props: ErrorProps): JSX.Element => {


    return <Box sx={{ flexGrow: 1, mt: 4 }}>
        <Grid justifyContent={"center"} container>
            <Grid container xs={9.3} justifyContent={"center"}>
                <Typography variant="h1">
                    {props.message}
                </Typography>
            </Grid>
        </Grid>
    </Box>
}