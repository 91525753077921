import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const CustomSlider = styled(Slider)({
    color: '#9e9e9e',
    height: 32, // Make the slider thicker
    padding:0,
    '& .MuiSlider-thumb': {
        height:32,
        width:16,
        borderRadius: 0, // Removes the border radius
        opacity:0,
        padding:0,
        margin:0,
    },
    '& .MuiSlider-track': {
        borderRadius: 0, 
        padding:0,
        margin:0,
    },
    '& .MuiSlider-rail': {
        borderRadius: 0, 
        padding:0,
        margin:0,
    },
    // Add other styling as needed
});

export const Track = (): JSX.Element => {

    const [[leftHandle,rightHandle],setHandle] = useState([0,100]); 

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        // Custom logic to update the value of both handles
        // You need to calculate the new values based on the mouse position and current values
        if(Array.isArray(newValue))
        {
            setHandle([newValue[0],newValue[1]])
        }
    };


    return <CustomSlider
        step={0.00001}
        color='primary'
        value={[leftHandle, rightHandle]} // Example values for the two handles
        onChange={handleSliderChange}
    />
}