import { Alert, Avatar, Button, Card, FormControl, Grid, Input, InputLabel, Paper, Snackbar, TextField } from "@mui/material";
import { User, UsersService } from "../../services/identity";
import { useState } from "react";
import { getDiscordAvatar } from "../../services/DiscordService";
import { useCurrentUserStore } from "../../hooks/CurrentUserStore";

export interface ProfileFormProps {
    user: User
    onSave: (user: User) => void;
}

interface SaveNotificationState {
    isOpen:boolean;
    severity: "success" | "error";
}

export const ProfileForm = (props: ProfileFormProps): JSX.Element => {

    const [formData, setFormData] = useState<User>({ ...props.user });
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [saveNotification,setSaveNotification] = useState<SaveNotificationState>({isOpen:false,severity:"success"});
    const localUser = useCurrentUserStore();

    const closeNotification = () => {
        var newSaveNotification = {...saveNotification};
        newSaveNotification.isOpen = false;
        setSaveNotification(newSaveNotification);
    }
 

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevent default form submission
        try {
            setIsSaving(true);
            await UsersService.updateUser(formData.id ?? "", formData);
            await localUser.fetchUser();
            setIsSaving(false);
            setSaveNotification({isOpen:true,severity:"success"});
            props.onSave(formData);
        } catch (error) {
            console.error('Error updating user:', error);
            setSaveNotification({isOpen:true,severity:"error"});
            // Handle error (show error message, etc.)
        }
    };

    const isAdminOrLoggedInUser = (): boolean => {
        if (localUser.user?.isAdmin) {
            return true;
        }
        if (localUser.user?.id == props.user.id) {
            return true;
        }
        return false;
    }


    return <Paper sx={{ p: 2 }} >
        <Snackbar anchorOrigin={{horizontal:"right",vertical:"top"}}  open={saveNotification.isOpen} onClose={closeNotification} autoHideDuration={3000} >
            <Alert severity={saveNotification.severity} sx={{ width: '100%' }}>
                User saved
            </Alert>
        </Snackbar>
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container justifyContent={"center"}>
                        {formData.avatar && <Avatar sx={{ width: 100, height: 100 }} src={getDiscordAvatar(props.user)} />}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Discord Name"
                        disabled
                        value={formData.userName || ''}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Display Name"
                        name="displayName"
                        value={formData.displayName || ''}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Discord ID"
                        value={formData.discordId || ''}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Is Admin"
                        value={formData.isAdmin ? 'Yes' : 'No'}
                        disabled
                    />
                </Grid>
                {isAdminOrLoggedInUser() ? <Grid item xs={12}>
                    <Grid container justifyContent={"right"}>
                        <Button type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                    </Grid>
                </Grid> : <></>}

            </Grid>
        </form>
    </Paper>
}