import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography, Box } from '@mui/material';

export interface CircularProgressWithLabelProps {
    progress: number;
    color?: 'primary' | 'secondary' | 'inherit';
    size?: number;
    animationDuration?: number;  // Optional animation duration in milliseconds
}

export const CircularProgressWithLabel = (props: CircularProgressWithLabelProps): JSX.Element => {
    const [animatedProgress, setAnimatedProgress] = useState(props.progress);

    useEffect(() => {
        const startProgress = animatedProgress;
        const endProgress = props.progress;
        const duration = props.animationDuration ?? 2500; // Default to 2500ms if no duration is provided

        const startTime = Date.now();

        const animate = () => {
            const currentTime = Date.now();
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);

            setAnimatedProgress(startProgress + (endProgress - startProgress) * progress);

            if (elapsedTime < duration) {
                requestAnimationFrame(animate);
            }
        };

        requestAnimationFrame(animate);
    }, [props.progress, props.animationDuration]); // Re-run the effect if progress or duration changes

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress
                size={props.size ?? 60}
                variant="determinate"
                value={animatedProgress}
                color={props.color}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography style={{ fontWeight: 'bold' }}>
                    {`${Math.round(animatedProgress)}%`}
                </Typography>
            </Box>
        </Box>
    );
};
