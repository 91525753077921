/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum VideoStateEnum {
    WAITING_FOR_LOW_QUALITY = 'WaitingForLowQuality',
    READY_TO_BE_CUT = 'ReadyToBeCut',
    CUT = 'Cut',
}
