import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { pink } from '@mui/material/colors';
import { ErrorMessage } from '../components/ErrorMessage';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6622CC',
            contrastText: '#FFFFFF', // Ensuring text is legible on the primary color
        },
        secondary: {
            main: '#FF499E',
            contrastText: '#FFFFFF', // Ensuring text is legible on the secondary color
        },
        error: {
            main: '#6B0504',
        },
        // You can define additional colors as parts of the palette
        info: {
            main: '#12EAEA',
        },
        success: {
            main: '#63C132',
        },
        background: {
            // You can use gradients for background as well, for example:
            default: 'linear-gradient(45deg, #6622CC 30%, #FF499E 90%)',
        },
        // ... add more custom colors if needed
    },
    // Other theme customizations can go here, such as typography, spacing, etc.
});

export const ExamplePage = (): JSX.Element => {
    return <Container maxWidth="md" style={{ marginTop: '2rem' }}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper style={{ padding: '1rem' }}>
                        <Typography variant="h5" component="h3">
                            This is a sheet of paper.
                        </Typography>
                        <Typography component="p">
                            Paper can be used to build surface or other elements for your application.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Word of the Day
                            </Typography>
                            <Typography variant="h5" component="h2">
                                be•nev•o•lent
                            </Typography>
                            <Typography color="textSecondary">
                                adjective
                            </Typography>
                            <Typography variant="body2" component="p">
                                well meaning and kindly.
                                <br />
                                {'"a benevolent smile"'}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Word of the Day
                            </Typography>
                            <Typography variant="h5" component="h2">
                                be•nev•o•lent
                            </Typography>
                            <Typography color="textSecondary">
                                adjective
                            </Typography>
                            <Typography variant="body2" component="p">
                                well meaning and kindly.
                                <br />
                                {'"a benevolent smile"'}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Word of the Day
                            </Typography>
                            <Typography variant="h5" component="h2">
                                be•nev•o•lent
                            </Typography>
                            <Typography color="textSecondary">
                                adjective
                            </Typography>
                            <Typography variant="body2" component="p">
                                well meaning and kindly.
                                <br />
                                {'"a benevolent smile"'}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant='contained'  color='secondary' size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Word of the Day
                            </Typography>
                            <Typography variant="h5" component="h2">
                                be•nev•o•lent
                            </Typography>
                            <Typography color="textSecondary">
                                adjective
                            </Typography>
                            <Typography variant="body2" component="p">
                                well meaning and kindly.
                                <br />
                                {'"a benevolent smile"'}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card>
                        <CardContent>
                            <Typography color="textSecondary" gutterBottom>
                                Word of the Day
                            </Typography>
                            <Typography variant="h5" component="h2">
                                be•nev•o•lent
                            </Typography>
                            <Typography color="textSecondary">
                                adjective
                            </Typography>
                            <Typography variant="body2" component="p">
                                well meaning and kindly.
                                <br />
                                {'"a benevolent smile"'}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Required"
                        defaultValue="Hello World"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Slider
                        defaultValue={30}
                        step={10}
                        marks
                        min={10}
                        max={110}
                        valueLabelDisplay="auto"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Switch />}
                        label="Enable Feature"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary">
                        Primary Button
                    </Button>
                    &nbsp;
                    <Button variant="contained" color="secondary">
                        Secondary Button
                    </Button>
                    <Button variant="outlined" color="secondary">
                        Secondary Button
                    </Button>
                    <Button variant="contained" color="error">
                        Secondary Button
                    </Button>
                    <Button variant="contained" color="success">
                        Secondary Button
                    </Button>
                    <Button variant="contained" color="warning">
                        Secondary Button
                    </Button>
                </Grid>
            </Grid>
        </Container>
}