import { create } from "zustand";
import { uploadFile } from "../services/SpaceService";
import axios, { AxiosError, CancelTokenSource } from "axios";
import { VideosService } from "../services/video";

export interface UploadedFile {
    file: File;  // Simple interface, you can extend it as needed
    progress: number;
    fileName: string;
    uploadUrl: string;
    error: boolean;
    errorMessage?: string;
    cancelTokenSource?: CancelTokenSource;
}

export interface UploadVideoState {
    uploadedFiles: UploadedFile[];
    addUploadedFile: (file: UploadedFile) => void;
    getUploadedFiles: () => UploadedFile[];
    removeUploadedFile: (index: number) => void;
}

export const useUploadedVideos = create<UploadVideoState>((set, get) => ({
    uploadedFiles: [],
    addUploadedFile: (file) => {
        const cancelTokenSource = axios.CancelToken.source();
        file.cancelTokenSource = cancelTokenSource;
        set(state => ({ uploadedFiles: [...state.uploadedFiles, file] }))

        const onProgress = (progress: number) => {
            set(state => {
                const updatedFiles = state.uploadedFiles.map(f => {
                    // Update only the file with the matching fileName
                    if (f.fileName === file.fileName) {
                        return { ...f, progress: progress };
                    }
                    return f;
                });
                return { uploadedFiles: updatedFiles };
            });
        };

        const onError = (error: AxiosError) => {
            set(state => {
                const updatedFiles = state.uploadedFiles.map(f => {
                    // Update only the file with the matching fileName
                    if (f.fileName === file.fileName) {
                        return { ...f, error: true, errorMessage: error.message };
                    }
                    return f;
                });
                return { uploadedFiles: updatedFiles };
            });
        };

        uploadFile(file.uploadUrl, file.file!, onProgress, file.cancelTokenSource, onError).then(async () => {
            console.log("DONE");

            await VideosService.createVideo({
                fileName: file.fileName, //Name of the url file
                name: file.file.name, //The name of the file ,
                fileCreationDate: file.file ? new Date(file.file.lastModified).toISOString() : null
            });

            set((state) => {
                const fileToUpdate = state.uploadedFiles.find(f => f.fileName === file.fileName);
                if (fileToUpdate && fileToUpdate.error) {
                    // If error is true, do not remove the file
                    console.log("File not removed due to error");
                    return { uploadedFiles: [...state.uploadedFiles] };
                } else {
                    // If error is false or file not found, remove the file
                    
                    const remainingFiles = state.uploadedFiles.filter(f => f.fileName !== file.fileName);
                    return { uploadedFiles: remainingFiles };
                }
            }); //remove file
        })
    },
    getUploadedFiles: () => get().uploadedFiles,
    removeUploadedFile: (index: number) => {
        set(state => {
            const fileToRemove = state.uploadedFiles[index];
            if (fileToRemove.cancelTokenSource) {
                fileToRemove.cancelTokenSource.cancel("Upload cancelled.");
            }
            // Creating a new array excluding the item at the specified index
            const newFiles = [...state.uploadedFiles];
            newFiles.splice(index, 1); // Remove the item at the index
            return { uploadedFiles: newFiles };
        });
    }
}));


export interface UploadSnackbarState {
    open: boolean;
    getState: () => boolean;
    setState: (isOpen: boolean) => void;
}

export const useUploadSnackbarState = create<UploadSnackbarState>((set, get) => ({
    getState: () => get().open,
    open: false,
    setState: (isOpen:boolean) => set(state => {
        return { open: isOpen }
    })
}));