import { useEffect, useState } from "react";
import { Video, VideosService } from "../services/video";

export enum SortOrder {
    Ascending = 'ascending',
    Descending = 'descending'
}

export const useVideos = (
    videoStatusId: string,
    sortPropertyFn?: (video: Video) => any,
    sortOrder: SortOrder = SortOrder.Ascending
): [Video[], boolean] => {
    const [videos, setVideos] = useState<Video[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchVideos = async () => {
            if(videos.length == 0)
            {
                setIsLoading(true);
            }
            try {
                const response = await VideosService.getVideos();
                // Perform sorting if sortPropertyFn is provided
                if (sortPropertyFn) {
                    const sorted = response.sort((a, b) => {
                        let valueA = sortPropertyFn(a);
                        let valueB = sortPropertyFn(b);

                        if (sortOrder === SortOrder.Ascending) {
                            return valueA < valueB ? -1 : (valueA > valueB ? 1 : 0);
                        } else {
                            return valueA > valueB ? -1 : (valueA < valueB ? 1 : 0);
                        }
                    });
                    setVideos(sorted);
                }
                else{
                    setVideos(response);
                }

            } catch (err) {
                // Handle error as needed
            } finally {
                setIsLoading(false);
            }
        };

        fetchVideos();
    }, [sortOrder,videoStatusId]);

    return [videos, isLoading];
};