import { createTheme, Shadows, Theme, ThemeOptions } from '@mui/material/styles';
import { create } from "zustand";

interface ThemeState {
  theme: Theme;
  toggleTheme: () => void;
}

const defaultTheme = createTheme();

const defaultShadows: ThemeOptions['shadows'] = [...defaultTheme.shadows];

const lightTheme = createTheme({
  shadows: defaultShadows.map(() => 'none') as Shadows,
  shape: {
    borderRadius: 0,
  },
  components : {
    MuiPaper : {
      // defaultProps : {
      //   elevation : 0
      // },
      styleOverrides : {
        rounded : {
          borderRadius :  "0px"
        }
      }
    }
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#711DB0', // green
    },
    secondary: {
      main: '#FFA732', // Blue
    },
    // background: {
    //   default: '#F5F5F5', // Very Light Gray
    // },
    // text: {
    //   primary: '#212121', // Dark Gray for contrast
    // },
    // success: {
    //   main: '#81C784', // Soft Green
    // },
    // warning: {
    //   main: '#FFA726', // Orange
    // }
  },
});


const darkTheme = createTheme({
  shadows: defaultShadows.map(() => 'none') as Shadows,
  shape: {
    borderRadius: 0,
  },
  components : {
    MuiPaper : {
      // defaultProps : {
      //   elevation : 0
      // },
      styleOverrides : {
        rounded : {
          borderRadius :  "0px"
        }
      }
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#711DB0', // Vibrant Blue
    },
    secondary: {
      main: '#FFA732', // Bright Orange
    },
    // background: {
    //   default: '#303030', // Slightly Lighter Dark Gray
    // },
    // text: {
    //   primary: '#E0E0E0', // Light Gray for readability
    // },
    // success: {
    //   main: '#4CAF50', // Rich Green
    // },
    // warning: {
    //   main: '#FFEB3B' // Bright Yellow
    // }
  },
});

const getPreferredTheme = () => {
  // Check for saved theme in localStorage
  const savedTheme = localStorage.getItem('theme');
  if (savedTheme) {
    return savedTheme === 'light' ? lightTheme : darkTheme;
  }

  // Fallback to OS preference
  const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  return prefersDarkMode ? darkTheme : lightTheme;
};

const useThemeStore = create<ThemeState>((set) => ({
  theme: getPreferredTheme(),
  toggleTheme: () => set((state) => {
    const newTheme = state.theme.palette.mode === 'light' ? darkTheme : lightTheme;
    localStorage.setItem('theme', newTheme.palette.mode); // Save new preference
    return { theme: newTheme };
  }),
}));

export default useThemeStore;