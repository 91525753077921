import { Box, Grid, Paper } from "@mui/material"
import { useParams } from "react-router-dom";
import RGL, { WidthProvider } from "react-grid-layout";
import { FileTree } from "../components/videoeditor/FileTree";
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { VideoEditorProjectBar } from "../components/videoeditor/VideoEditorProjectBar";
import { useState } from "react";
import { Track } from "../components/videoeditor/Track";
import { TrackContainer } from "../components/videoeditor/TrackContainer";

const ResponsiveGridLayout = WidthProvider(RGL);


const ResizeHandle = () : JSX.Element => {
return <></>
}

export const EditVideoPage = (): JSX.Element => {
    const { id } = useParams();

    const initialLayout  : RGL.Layout[] = [
        { i: 'files', x: 0, y: 0, w: 4, h: 15, isResizable:true, isDraggable:false, resizeHandles:["e"], maxW:10  },  // 1st column of the top row
        { i: 'video', x: 4, y: 0, w: 16, h: 15, isResizable:true, isDraggable:false,resizeHandles:[] },  // 2nd column of the top row
        { i: 'info', x: 20, y: 0, w: 4, h: 15, isResizable:true, isDraggable:false, resizeHandles:["w"],maxW:10 },  // 3rd column of the top row
        { i: 'tracks', x: 0, y: 15, w: 24, h: 9, static:false, isDraggable:false, resizeHandles:["n"] }, // Bottom row
    ];

    const [layout, setLayout] = useState(initialLayout);

    const onResize = (layout: RGL.Layout[], oldItem: RGL.Layout, newItem: RGL.Layout) => {
        if (newItem.i === 'files') {
            let video : RGL.Layout | null = null;
            let info : RGL.Layout | null = null;
            let index = -1;
            const updatedLayout = layout.map((item,i) => {
                item.w = Math.min(item.maxW ?? 9999999,item.w);
                if (item.i === 'video') {
                    video = item;
                    index = i;
                }
                else if(item.i === 'info')
                {
                    info = item;
                }
                if(video != null && info != null)
                {
                    video.x = newItem.x + newItem.w;
                    video.w = info.x - video.x;
                }
                return item;
            });


            setLayout(updatedLayout);
            return;
        }
        if (newItem.i === 'info') {
            const updatedLayout = layout.map(item => {
                item.w = Math.min(item.maxW ?? 9999999,item.w);
                if (item.i === 'video') {
                    let w = newItem.x - item.x;
                    item.w = w;
                }
                return item;
            });
            setLayout(updatedLayout);
            return;
        }

        if (newItem.i === 'tracks') {
            const updatedLayout = layout.map(item => {
                item.w = Math.min(item.maxW ?? 9999999,item.w);
                if (item.i !== 'tracks') {

                    item.h = newItem.y;
                }
                return item;
            });
            setLayout(updatedLayout);
            return;
        }
    };


    return <div style={{height:"calc(100vh - 128px)"}}>
        <VideoEditorProjectBar/>
        <ResponsiveGridLayout 
            allowOverlap={true} 
            layout={layout} 
            cols={24} 
            rowHeight={20} 
            maxRows={24} 
            style={{height:"100%"}}
            onResize={onResize}
        >
            <Paper key="files" sx={{overflow:"hidden",width:"100%","height":"100%"}}>
                <FileTree/>
            </Paper>
            <div key="video" className="grid-item">video</div>
            <div key="info" className="grid-item">Info</div>
            <Grid key="tracks" container p={1}>
                <Grid item xs={12}>
                    <TrackContainer tracks={[
                        {id: "1", name: "name", type: "movie"},
                        {id: "2", name: "name", type: "movie"},
                        {id: "3", name: "name", type: "movie"}
                    ]}/>
                </Grid>
            </Grid>
        </ResponsiveGridLayout>
    </div>

}