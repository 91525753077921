import { Box, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material"
import { TreeItem, TreeView } from "@mui/x-tree-view";
import FolderIcon from '@mui/icons-material/Folder';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import MovieIcon from '@mui/icons-material/Movie';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import React, { useState } from "react";
import { SortOrder, useVideos } from "../../hooks/VideoHooks";


const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
    '& .MuiTreeItem-iconContainer': {
        '& .close': {
            opacity: 0.3,
        },
    },
    '& .MuiTreeItem-group': {
        marginLeft: 15,
        paddingLeft: 18,
    },
}));

const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault(); // Necessary to allow dropping
};

export const FileTree = (): JSX.Element => {

    const [search, setSearch] = useState("");
    const [videos] = useVideos("", (x) => x.fileCreated, SortOrder.Ascending);

    const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    return <Box sx={{ height: "100%" }}>
        <Grid container alignItems={"center"} pt={1} justifyContent={"space-between"}>
            <Grid item pl={1}>
                Files
            </Grid>
            <Grid item pr={1}>
                <IconButton aria-label="refresh" size="small">
                    <CachedIcon />
                </IconButton>
            </Grid>
        </Grid>
        <Box p={1}>
            <TextField
                size="small"
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                onChange={onSearchChange}
            />
        </Box>
        <TreeView
            defaultCollapseIcon={<FolderIcon />}
            defaultExpandIcon={<FolderIcon />}
            defaultExpanded={['1']}
            sx={{ overflowY: "scroll", height: "200%" }}
            onDragOver={handleDragOver}
        >
            <StyledTreeItem nodeId="1" label="My Videos" icon={<FolderIcon />}>
                {
                    videos.filter(video => video.name?.toLocaleLowerCase().indexOf(search) != -1).map(video => {
                        return (
                            <StyledTreeItem
                                draggable={false}
                                nodeId={video.id ?? ""}
                                label={<Typography sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{video.name}</Typography>}
                                icon={<MovieIcon />} 
                            />
                        );
                    })}
            </StyledTreeItem>
        </TreeView>
    </Box>
}