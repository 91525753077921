/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateVideoCommand } from '../models/CreateVideoCommand';
import type { GetVideosQuery } from '../models/GetVideosQuery';
import type { UploadUrl } from '../models/UploadUrl';
import type { Video } from '../models/Video';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VideosService {

    /**
     * @returns UploadUrl Success
     * @throws ApiError
     */
    public static getUploadUrl(): CancelablePromise<UploadUrl> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/video/api/Videos/UploadUrl',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns string Success
     * @throws ApiError
     */
    public static createVideo(
requestBody?: CreateVideoCommand,
): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/video/api/Videos',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @param query 
     * @returns Video Success
     * @throws ApiError
     */
    public static getVideos(
query?: GetVideosQuery,
): CancelablePromise<Array<Video>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/video/api/Videos',
            query: {
                'query': query,
            },
            errors: {
                401: `Unauthorized`,
            },
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static test(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/video/api/Videos/Test',
            errors: {
                401: `Unauthorized`,
            },
        });
    }

}
