import React, { useCallback, useState } from 'react';
import Button, { ButtonPropsColorOverrides } from "@mui/material/Button";
import { styled, useTheme } from '@mui/material/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import './UploadButton.scss';
import { Box } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export interface UploadButtonProps {
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadButton = (props: UploadButtonProps) : JSX.Element => {
    const [dragOver, setDragOver] = useState(false);

    const theme = useTheme();
    const buttonPropsOverides : ButtonPropsColorOverrides = {
    }

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setDragOver(false);

        if (props.onChange && event.dataTransfer.files) {
            const fileList = event.dataTransfer.files;
    
            // Create a synthetic event
            const fileInputEvent = {
                target: {
                    files: fileList,
                    value: '',
                    type: 'file',
                    // ... add other properties as needed to mimic HTMLInputElement
                },
                currentTarget: {
                    files: fileList,
                    value: '',
                    type: 'file',
                    // ... add other properties as needed to mimic HTMLInputElement
                },
                bubbles: event.bubbles,
                cancelable: event.cancelable,
                defaultPrevented: event.defaultPrevented,
                // ... add other properties from the event as needed
            } as unknown as React.ChangeEvent<HTMLInputElement>; // Cast to the desired type
    
            props.onChange(fileInputEvent);
        }
    };

    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange) {
            props.onChange(event);
        }
    };

    const buttonClass = dragOver ? 'upload-button-hover upload-button' : 'upload-button';

    return (
        <Box 
            onDragOver={handleDragOver} 
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={`upload-button-container ${dragOver ? 'drag-over' : ''}`}
            // Add additional styling for dragOver state if desired
        >
            <Button
                component="label"
                className={buttonClass}
                fullWidth
                sx={{ p: 2 }}
                variant="outlined"
                color='secondary'
                startIcon={<FileUploadIcon />}
            >
                Upload
                <VisuallyHiddenInput 
                    type="file" 
                    multiple={true} 
                    onChange={handleFileInputChange} 
                />
            </Button>
        </Box>
    );
};

export default UploadButton;
