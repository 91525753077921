import { Box, Button, Grid, IconButton, LinearProgress, Paper, SxProps, Theme, Typography, useTheme } from "@mui/material"
import { UploadProgressBar } from "./UploadProgressBar"
import CloseIcon from '@mui/icons-material/Close';
import { useUploadedVideos } from "../../hooks/UploadVideoStore";
import { VideoUploadButton } from "../Video/VideoUploadButton";


export const UploadContainer = (): JSX.Element => {

    const theme = useTheme();
    const [ uploadedFiles, removeUploadedFile ] = useUploadedVideos(state => [state.uploadedFiles, state.removeUploadedFile]);

    const getFileScrollStyle = (theme: Theme): SxProps => ({
        overflowY: 'auto', 
        overflowX: 'hidden', 
        maxHeight: '400px',
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.1)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.dark, // Use primary color
            borderRadius: '0.4em'
        }
    });

    const onAbort = (index: number) => {
        removeUploadedFile(index);
    }

    const fileScrollStyle = getFileScrollStyle(theme);


    return <Box sx={{
        minWidth: { xs: 'none', sm: 400 },
        maxWidth: { xs: 'none', sm: 400 }
    }}>
        <Grid container justifyContent={"center"}>
            <Grid item xs={12}>
                <VideoUploadButton />
            </Grid>
            <Grid item xs={12} sx={fileScrollStyle}>
                {uploadedFiles.map((file,index) => (
                    <UploadProgressBar error={file.error} errorMessage={file.errorMessage} onAbort={() => onAbort(index)} key={index} progress={file.progress} title={file.file?.name ?? ""}/>
                ))}
            </Grid>
        </Grid>
    </Box>
}


// {/* <Grid>
//             <Grid  item>
//                 <Paper>
//                     <Typography variant="h5">Upload</Typography>
//                 </Paper>
//             </Grid>

//             <Grid  item>
//             </Grid>
//             <Grid  item>
//                 <Grid container>
//                     <LinearProgress variant="determinate" value={50} />
//                     <LinearProgress variant="determinate" value={50} />
//                     <LinearProgress variant="determinate" value={50} />
//                     <LinearProgress variant="determinate" value={50} />
//                 </Grid>
//             </Grid>
//         </Grid> */}