import { create } from "zustand";
import { User, UsersService } from '../services/identity';

interface UserState {
    user: User|null; // Replace 'any' with your User type
    loading: boolean;
    fetchInterval: NodeJS.Timer | null;
    setUser: (user: User|null) => void; // Replace 'any' with your User type
    fetchUser: () => Promise<void>;
    stopAutoFetch: () => void;
}


export const useCurrentUserStore = create<UserState>((set, get) => {
    // Function to fetch user
    const fetchUser = async () => {
        try {
            const fetchedUser = await UsersService.getCurrentUser();
            set((state) => ({ ...state, user: fetchedUser, loading: false }));
        } catch (error) {
            console.error('Error fetching user:', error);
            set((state) => ({ ...state, user: null, loading: false }));
        }
    };

    // Start fetching immediately and then every 5 minutes
    const fetchInterval = setInterval(() => {
        fetchUser();
    },60 * 1000 * 10); //10 minutes

    return {
        user: null,
        loading: true,
        fetchInterval: fetchInterval,
        setUser: (user) => set((state) => ({ ...state, user })),
        fetchUser: fetchUser,
        stopAutoFetch: () => {
            clearInterval(get().fetchInterval!);
            set((state) => ({ ...state, fetchInterval: null }));
        }
    };
});