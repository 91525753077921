import { UploadedFile, useUploadSnackbarState, useUploadedVideos } from "../../hooks/UploadVideoStore";
import { VideosService } from "../../services/video";
import UploadButton from "../Buttons/UploadButton"

export const VideoUploadButton = () : JSX.Element => {

    const [addUploadedFile,uploadedFiles] = useUploadedVideos(state => [state.addUploadedFile, state.uploadedFiles]);
    const [setState] = useUploadSnackbarState(state => [state.setState]);

    const onChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            // Loop through all the selected files
            for (let i = 0; i < event.target.files.length; i++) {
                const file = event.target.files[i];
                const uploadUrl = await VideosService.getUploadUrl();
                const uploadedFile : UploadedFile = {
                    file: file,
                    progress: 0,
                    fileName: uploadUrl.fileName!,
                    uploadUrl: uploadUrl.url!,
                    error: false,
                };
                if(uploadedFiles.length == 0) //open snackbar if no files inside.
                {
                    setState(true);
                }
                addUploadedFile(uploadedFile); // Add each file to the Zustand store
            }
        }
    };

    return <UploadButton onChange={onChange}/>
}