// store.ts
import { create } from 'zustand';
import { Status, StatusService } from '../services/video';
import { useEffect } from 'react';

interface StatusState {
    status: Status | null;
    setStatus: (status: Status) => void;
}

export const useStatusStore = create<StatusState>((set) => ({
    status: null, // initial state
    setStatus: (status: Status) => set({ status }),
}));


export const useFetchStatus = () => {
    const status = useStatusStore((state) => state.status); // Access the current status
    const setStatus = useStatusStore((state) => state.setStatus);
  
    useEffect(() => {
      const fetchStatus = async () => {
        try {
          const statusResponse = await StatusService.getStatus();
          setStatus(statusResponse);
        } catch (error) {
          console.error('Failed to fetch status:', error);
        }
      };
  
      // Fetch status immediately and then set up the interval
      fetchStatus();
      const interval = setInterval(fetchStatus, 5000);
  
      // Clear interval on component unmount
      return () => clearInterval(interval);
    }, [setStatus]); // Dependencies array
  
    return status; // Return the current status
  };
