import { Box, IconButton, ImageList, ImageListItem, ImageListItemBar, Menu, MenuItem, Paper, Typography, makeStyles, useMediaQuery, useTheme } from "@mui/material";
import MoreIcon from '@mui/icons-material/MoreVert';
import { Link } from "react-router-dom";
import { useState } from "react";
import { CircularProgressWithLabel } from "../Common/CircularProgressWithlabel";


export interface VideoContainerProps {
    videos: Video[];
}

export interface Video {
    id: string;
    thumbnail: string;
    name: string;
    link?: string;
    blur: boolean;
    isProcessing?: boolean;
    progress: number;
}



export const VideoContainer = (props: VideoContainerProps): JSX.Element => {
    const isSmallScreen = useMediaQuery('(max-width: 649.95px)'); // Small screens
    const isMediumScreen = useMediaQuery('(min-width: 650px) and (max-width: 1200px)'); // Medium screens
    let columns = 3; // Default to 3 columns for large screens

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    if (isMediumScreen) {
        columns = 2; // 2 columns for medium screens
    }

    if (isSmallScreen) {
        columns = 1; // 1 column for small screens
    }


    const theme = useTheme();


    return <Box>
        <ImageList cols={columns} gap={12} sx={{ flexGrow: 1, mb: 0, mt: 0, overflow: "hidden" }}>
            {props.videos.map((video, i) => (
                <ImageListItem key={video.id} sx={{ overflow: "hidden" }}>
                    <img
                        srcSet={`${video.thumbnail}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        src={`${video.thumbnail}?w=248&fit=crop&auto=format`}
                        alt={video.name}
                        loading="lazy"
                        style={video.blur ? { filter: "blur(10px)" } : {}}
                    />
                    {video.isProcessing ? <Box sx={{position:"absolute", left:"50%", top:"50%", transform:"translate(-50%,-50%)"}}>
                        <CircularProgressWithLabel size={130} color="secondary" progress={video.progress} />
                    </Box> : null}
                    <ImageListItemBar
                        title={
                            <Link to={video.link ?? ""} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <Typography>{video.name}</Typography>
                            </Link>
                        }
                        subtitle={""}
                        sx={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}
                        actionIcon={
                            <IconButton
                                onClick={(event) => { handleClick(event) }}
                                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                aria-label={`info about ${video.name}`}
                            >
                                <MoreIcon color="secondary" />
                            </IconButton>
                        }
                    />
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        disableScrollLock={true}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <Link to={video.link ?? ""} style={{ textDecoration: 'none', color: 'inherit' }}>
                                Edit
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>Share</MenuItem>
                        <MenuItem onClick={handleClose}>Delete</MenuItem>
                    </Menu>
                </ImageListItem>
            ))}
        </ImageList>
    </Box>
}