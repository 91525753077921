import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const TimelineSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-thumb': {
      position: 'relative', // Ensure the thumb can contain absolute positioned elements
      zIndex: 2, // Ensure the thumb is above the line
      '&::after': {
        content: '""', // Necessary for pseudo-elements
        position: 'absolute',
        top: '0%', // Centered vertically on the thumb
        left: '50%', // Center horizontally within the thumb
        transform: 'translate(-50%, -0%)', // Center the line
        height: '500px', // The desired length of the line
        width: '1px', // One pixel line width
        backgroundColor: theme.palette.primary.main, // Use primary color from theme
        zIndex: 1, // Ensure the line is above other elements but below the thumb
      },
    },
    // Add other styling as needed
  }));

export interface TimelineProps {
    time: number;
}

export const Timeline = (props: TimelineProps): JSX.Element => {

    const [time,setHandle] = useState(props.time); 

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        if(!Array.isArray(newValue))
        {
            setHandle(newValue)
        }
        else{
            setHandle(newValue[0])
        }
    };

    return <TimelineSlider
        color='primary'
        step={0.001}
        value={[time]} // Example values for the two handles
        onChange={handleSliderChange}
    />
}